
.DragII {
    display: flex;
    flex-direction: column;
    user-select: none;
    display: block;
    margin-left: auto;
    margin-right: auto
}

svg {
    margin: 1rem 0;
    cursor: crosshair;
}
